<template>
    <v-container fluid>
        <v-form @submit.prevent="getSpecialties">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'specialty.create'}"
                   color="indigo" fab fixed top right class="v-btn--add-form-top white--text">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="10">
                                <v-subheader class="headline">{{ $t('search_specialty') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn :to="{name: 'specialty.create'}" class="white--text" color="indigo">
                                    {{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="specialty" rules="min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="specialty" type="text" :error-messages="errors"
                                                  :disabled="loading" :label="$t('specialty_name')"
                                                  prepend-icon="mdi-gender-male-female"
                                                  color="primary" clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-4">
                        <v-row>
                            <v-col cols="12" class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="headers" :items="specialtyItems" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="mr-2" @click="editSpecialty(item)" :title="$t('edit')">
                                    mdi-square-edit-outline
                                </v-icon>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="deleteSpecialty(item)" :title="$t('delete')">
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                            <span>{{$t('delete')}}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex"

export default {
    name: "Specialties",
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            specialty: null,
            sortBy: "name",
            sortDir: true,
            loading: false,
            specialtyItems: [],
            headers: [
                {
                    text: this.$t('name'),
                    align: "left",
                    sortable: true,
                    value: "name"
                },
                {
                    text: this.$t('actions'),
                    align: "center",
                    value: 'action',
                    sortable: false,
                    width: 120,
                },
            ],
        }
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage']),
    },
    mounted() {
        this.getSpecialties()
    },
    methods: {
        editSpecialty(item) {
            this.$router.push({
                name: 'specialty.edit',
                params: {
                    id: item.id
                }
            })
        },
        async getSpecialties() {
            var _this = this
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.specialty) {
                params.specialty = this.specialty
            }
            await this.$http
                .get("admin/specialty", {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.specialtyItems = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_specialties'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async deleteSpecialty(item) {
            if (confirm(this.$t('delete_specialty'))) {
                var _this = this
                this.loading = true
                await this.$http
                    .delete(`admin/specialty/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('specialty_has_been_deleted'))
                        this.getSpecialties()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('specialty_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        }
    }
}
</script>
